import React, {useState, useEffect, useContext} from 'react';
import {Container, Spinner} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import List from '../../components/List/List';
import ProjectItem from '../../components/ProjectItem/ProjectItem';
import ModalEditProject from '../../components/Modals/ModalEditProject';
import {sort} from '../../utils/someFunctions';
import { IProject } from '../../types/types';
import { fetchProjects, deleteProject } from '../../http/projectsAPI';
// import {autos, books, employees, marvel, store, windows, zammit} from '../../assets/projects/index';
import { Context } from '../..';

import './portolioPage.sass';


const PortfolioPage: React.FC = () => {
    const {admin} = useContext(Context);

    const [projects, setProjects] = useState<IProject[]>([
        // {
        //     "id":9,
        //     "title":"Остекление балконов",
        //     "site":"https://windows.lexkorn.ru",
        //     "git":"https://github.com/LexKorn/window_project",
        //     "stack":"JavaScript ES6, HTML5, CSS3, JQuery, Gulp",
        //     "description":"Во время обучения на Udemy",
        //     "cover": windows
        // },
        // {
        //     "id":13,
        //     "title":"EMPLOYEES",
        //     "site":"https://employees.lexkorn.ru/",
        //     "git":"https://github.com/LexKorn/my_emp_self",
        //     "stack":"React 17, SASS",
        //     "description":"Во время обучения на Udemy",
        //     "cover":employees
        // },
        // {
        //     "id":14,
        //     "title":"ПОРТАЛ MARVEL",
        //     "site":"https://marvel.lexkorn.ru/",
        //     "git":"https://github.com/LexKorn/my-marvel-hooks",
        //     "stack":"React 17, SASS, BEM",
        //     "description":"Во время обучения на Udemy",
        //     "cover":marvel
        // },
        // {
        //     "id":15,
        //     "title":"ОНЛАЙН МАГАЗИН",
        //     "site":"https://store.lexkorn.ru/",
        //     "git":"https://github.com/LexKorn/online_store_mysql",
        //     "stack":"Full-stack SPA. TypeScript, React 18, SASS, BEM, React bootstrap, MobX, Node.js, Express.js, MySQL",
        //     "description":"Во время обучения...",
        //     "cover":store
        // },
        // {
        //     "id":16,
        //     "title":"MY BOOKS",
        //     "site":"https://books.lexkorn.ru/",
        //     "git":"https://github.com/LexKorn/my_books_v2_client","stack":"Full-stack SPA. TypeScript, React 18, SASS, BEM, React bootstrap, MobX, Node.js, Express.js, MySQL",
        //     "description":"Pet Project",
        //     "cover":books
        // },
        // {
        //     "id":17,
        //     "title":"ИБ ЦАММИТ",
        //     "site":"https://ib-zammit.ru",
        //     "git":"https://github.com/LexKorn/NERM_zammit_client",
        //     "stack":"Full-stack SPA. TypeScript, React 18, SASS, BEM, React bootstrap, MobX, Node.js, Express.js, MySQL",
        //     "description":"Фриланс проект. Переделал старый вариант корпоративного сайта (HTML, CSS, JS) на сайт с самописной CMS",
        //     "cover":zammit
        // },
        // {
        //     "id":18,
        //     "title":"Автосервис",
        //     "site":"https://autos.lexkorn.ru/",
        //     "git":"https://github.com/LexKorn/NERM_autoservise_client.git",
        //     "stack":"Full-stack SPA. TypeScript, React 18, SASS, BEM, React bootstrap, MobX, Node.js, Express.js, MySQL",
        //     "description":"Фриланс проект. Для входа на сайт использовать тестовые данные: email: test@test.ru, password: test1234",
        //     "cover":autos
        // }
    ]);
    const [sortProjects, setSortProjects] = useState<IProject[]>([]);
    const [project, setProject] = useState<IProject>({} as IProject);
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [toggle, setToggle] = useState<boolean>(false);

    useEffect(() => {
        fetchProjects()
            .then(data => {
                setProjects(data);
                setLoading(false);
            })
            .then(() => admin.setConnect(true))
            .catch(err => {
                alert('Произошла ошибка при загрузке данных с удалённого сервера.');
                admin.setConnect(false);
                console.log(JSON.parse(err.request.response).message);
            });
    }, [toggle, visible]);

    useEffect(() => {
        setSortProjects(sort(projects));
    }, [projects]);

    const removeItem = (item: IProject) => {
        if (window.confirm(`Ты действительно хочешь удалить проект "${item.title}"?`)) {
            deleteProject(item.id).then(() => setToggle(!toggle));
        }    
    };

    const editItem = (item: IProject) => {
        setProject(item);
        setVisible(true);
    };

    return (
        <Container className='portfolio'>
            <Helmet>
                <title>Портфолио | Проекты</title>
                <meta name="description" content="Портфолио | Проекты" />
            </Helmet>

            {loading ?
                <Spinner animation="border" variant="light" />
                :
                <List 
                    items={sortProjects}
                    renderItem={(item: IProject) => 
                        <ProjectItem 
                            onDelete={() => removeItem(item)} 
                            onEdit={() => editItem(item)} 
                            item={item} 
                            key={item.id}
                        />
                    } 
                />
            }
            <ModalEditProject show={visible} onHide={() => setVisible(false)} project={project} />
        </Container>
    );
};

export default PortfolioPage;