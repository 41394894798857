import React, {useState, useEffect, useContext} from 'react';
import {Container, Spinner} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import { ISkill } from '../../types/types';
import { fetchSkills } from '../../http/skillsAPI';
import SkillCard from '../../components/SkillCard/SkillCard';
import ParticlesFon from '../../components/Particles/ParticlesFon';
import { Context } from '../..';
// import {js, react, redux, ts, axios, bem, git, mobx, bootstrap, css, expressjs, figma, gulp, hook, html, jquery, materialui, mern, mongodb, mysql, nest, nodejs, nextjs, npm, postgresql, postman, rest, scss} from '../../assets/skills/index';

import './skillsPage.sass';


const SkillsPage: React.FC = () => {
    const {admin} = useContext(Context);
    const [skills, setSkills] = useState<ISkill[]>([
        // {id: 49, icon: js, title: "JavaScript"},
        // {id: 50, icon: ts, title: ""},
        // {id: 51, icon: react, title: "React"},
        // {id: 52, icon: mobx, title: "MobX"},
        // {id: 53, icon: redux, title: "Redux"},
        // {id: 54, icon: bootstrap, title: "Bootstrap"},
        // {id: 55, icon: materialui, title: "Material UI"},
        // {id: 56, icon: scss, title: "SASS / SCSS"},
        // {id: 57, icon: css, title: "CSS3"},
        // {id: 58, icon: html, title: "HTML5"},
        // {id: 59, icon: git, title: "Git / GitHub"},
        // {id: 60, icon: axios, title: ""},
        // {id: 61, icon: nextjs, title: "Next JS"},
        // {id: 62, icon: nodejs, title: "Node JS"},
        // {id: 63, icon: expressjs, title: "Express JS"},
        // {id: 64, icon: mern, title: "MERN"},
        // {id: 66, icon: mongodb, title: ""},
        // {id: 67, icon: mysql, title: "MySQL"},
        // {id: 68, icon: postgresql, title: "PostgreSQL"},
        // {id: 69, icon: postman, title: "Postman"},
        // {id: 70, icon: rest, title: "REST API"},
        // {id: 71, icon: npm, title: ""},
        // {id: 72, icon: nest, title: "Nest JS"},
        // {id: 73, icon: gulp, title: "Gulp"},
        // {id: 74, icon: bem, title: "BEM"},
        // {id: 75, icon: hook, title: "Hooks"},
        // {id: 76, icon: figma, title: "Figma"},
        // {id: 77, icon: jquery, title: "JQuery"}
    ]);
    const [loading, setLoading] = useState<boolean>(true);
    const [toggle, setToggle] = useState<boolean>(false);

    useEffect(() => {
        fetchSkills()
            .then( data => {
                setSkills(data);
                admin.setConnect(true);
                setLoading(false);
            })
            .catch(err => {
                alert('Произошла ошибка при загрузке данных с удалённого сервера.');
                admin.setConnect(false);
                console.log(JSON.parse(err.request.response).message);
            });
    }, [toggle]);
    

    return (
        <div>
            <Helmet>
                <title>Портфолио | Навыки</title>
                <meta name="description" content="Портфолио | Навыки" />
            </Helmet>

            <ParticlesFon />
                <Container className='skills' >
                    {loading ?
                        <Spinner animation="border" variant="light" />
                        :
                        skills.map(skill =>
                            <SkillCard 
                                icon={skill.icon} 
                                title={skill.title} 
                                id={skill.id} 
                                toggle={toggle} 
                                setToggle={setToggle} 
                                key={skill.id} 
                            />
                        )
                    }
                </Container>
        </div>
    );
};

export default SkillsPage;