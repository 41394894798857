import React, {useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {Button} from 'react-bootstrap';

import { Context } from '../..';

import { deleteSkill } from '../../http/skillsAPI';

import './skillCard.sass';

interface ScillCardProps {
    id: number;
    icon: string;
    title: string;
    toggle: boolean;
    setToggle: (toggle: boolean) => void;
};


const SkillCard: React.FC<ScillCardProps> = observer(({id, icon, title, toggle, setToggle}) => {
    const {admin} = useContext(Context);

    const removeSkill = () => {
        if (window.confirm(`Ты действительно хочешь удалить навык "${title}"?`)) {
            deleteSkill(id).then(() => setToggle(!toggle));
        }    
    };

    return (
        <div className="skill-card">
            <img className="skill-card__icon" src={admin.connect ? process.env.REACT_APP_API_URL + icon : icon} />
            <div className="skill-card__title">{title}</div>
            {admin.isAuth && <Button variant="danger" onClick={() => removeSkill()}>Удалить</Button>}
        </div>
    );
});

export default SkillCard;