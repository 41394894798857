import React, {useContext, useState, useEffect} from 'react';
import {Container, Tab, Tabs, Spinner} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import ModalEditExp from '../../components/Modals/ModalEditExp';
import {sort} from '../../utils/someFunctions';
import { IAbout, IEducation, IExperience } from '../../types/types';
import { photo_me } from '../../assets/about';
import { Context } from '../..';
import { fetchEdus } from '../../http/eduAPI';
import { fetchExps } from '../../http/expAPI';
import { deleteEdu } from '../../http/eduAPI';
import { deleteExp } from '../../http/expAPI';

import './aboutPage.sass';


const AboutPage: React.FC = () => {
    const {admin} = useContext(Context);
    const [education, setEducation] = useState<IEducation[]>([
        // {
        //     id: 9,
        //     date: '2002-2008',
        //     institute: 'НИУ Московский Энергетический Институт',
        //     description: 'Радиотехнический факультет, Диплом инженера'
        // },
        // {
        //     id: 11,
        //     date: '2021-2022',
        //     institute: 'Udemy',
        //     description: 'WEB-разработчик'
        // },
        // {
        //     id: 12,
        //     date: '2021-2022',
        //     institute: 'Udemy',
        //     description: 'Полный курс по JavaScript + React - с нуля до результата'
        // },
        
    ]);
    const [sortEducation, setSortEducation] = useState<IEducation[]>([]);
    const [experience, setExperience] = useState<IExperience[]>([
        // {
        //     id: 8,
        //     date: '06.2018-06.2021',
        //     institute: 'Инженерное бюро Цаммит',
        //     description: 'Ведущий инженер-проектировщик слаботочных систем: СКС и ЛВС, телефония, интернет и телевидение, системы безопасности и системы пожарной безопасности.'
        // },
        // {
        //     id: 10,
        //     date: '12.2021-01.2022',
        //     institute: 'Инженерное бюро Цаммит',
        //     description: 'Фриланс проект. Разработка корпоративного сайта'
        // },
        // {
        //     id: 11,
        //     date: '10.2022-11.2022',
        //     institute: 'Pet Project',
        //     description: 'Портал книг'
        // },
        // {
        //     id: 12,
        //     date: '11.2022-12.2022',
        //     institute: 'Инженерное бюро Цаммит',
        //     description: 'Фриланс проект. Переделал старый вариант сайта на сайт с самописной CMS'
        // },
        // {
        //     id: 13,
        //     date: '01.2023-04.2023',
        //     institute: 'Автосервис',
        //     description: 'Фриланс проект'
        // },
    ]);
    const [sortExperience, setSortExperience] = useState<IExperience[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [item, setItem] = useState<IExperience>({} as IExperience);
    const [visible, setVisible] = useState<boolean>(false);
    const [exp, setExp] = useState<boolean>(false);
    const [toggle, setToggle] = useState<boolean>(false);

    useEffect(() => {
        fetchExps().then(data => setExperience(data));
        fetchEdus()
            .then(data => {
                setEducation(data);
                setLoading(false);
            })
            .catch(err => {
                alert('Произошла ошибка при загрузке данных с удалённого сервера.');
                console.log(JSON.parse(err.request.response).message);
            });
    }, [visible, toggle]);

    useEffect(() => {
        setSortEducation(sort(education));
    }, [education]);

    useEffect(() => {
        setSortExperience(sort(experience));
    }, [experience]);

    const about: IAbout = {
        id: 1,
        photo: photo_me,
        title: 'Меня зовут Алексей',
        description: [
            {
                id: 1,
                description: "В 2008 окончил Московский Энергетический Институт на Радиотехническом факультете. На последнем курсе начал заниматься проектированием слаботочных систем. Успешно проработав в этой сфере, с позиции чертёжника вырос до главного инженера."
            },
            {
                id: 2,
                description: "С 2020 увлёкся программированием, выбрал frontend. Прошел длительное обучение на площадке Udemy. Развиваюсь в направлении Frontend (TypeScript, JavaScript, React, Redux, MobX) + FullStack (back на Node.js + Express / Nest). Есть опыт коммерческой разработки."
            },
            {
                id: 3,
                description: "Мои сильные стороны: хорошие коммуникативные навыки, аналитический склад ума, высокий уровень усидчивости, умение работать в команде."
            },
            {
                id: 4,
                description: "У меня огромное желание обучаться новому и совершенствовать свои hard skills во фронтенде. Мои увлечения: занимаюсь спортом, люблю путешествовать."
            },
        ]
    };

    // @ts-ignore
    const onRemove = (item: IExperience | IEducation, e) => {
        if (window.confirm(`Ты действительно хочешь удалить "${item.institute}"?`)) {
            if (e.target.parentNode.parentNode.parentNode.parentNode.getAttribute('id') === 'fill-tab-example-tabpane-exp') {
                deleteExp(item.id).then(() => setToggle(!toggle));
            } else {
                deleteEdu(item.id).then(() => setToggle(!toggle));
            }
        }    
    };

    // @ts-ignore
    const onEdit = (item: IExperience | IEducation, e) => {
        if (e.target.parentNode.parentNode.parentNode.parentNode.getAttribute('id') === 'fill-tab-example-tabpane-exp') {
            setExp(true);
        } else {
            setExp(false);
        }

        setItem(item);
        setVisible(true);
    };

    return (
        <div>
            <Helmet>
                <title>Портфолио | Обо мне</title>
                <meta name="description" content="Портфолио | Обо мне" />
            </Helmet>

            <Container className='about' >
                <div className="about__wrapper">
                    <img src={about.photo} className='about__img' alt="" />
                    <div className='about__text'>
                        <div className='about__title'>{about.title}</div>
                        {about.description.map(descr => 
                            <p className='about__description' key={descr.id} >{descr.description}</p>
                        )}
                        {loading ?
                            <Spinner animation="border" variant="light" />
                            :
                            <Tabs
                                defaultActiveKey="edu"
                                id="fill-tab-example"
                                className="about__tabs"
                                fill
                                >
                                <Tab eventKey="edu" title="Образование">
                                    <ul className='about__experience_list'>
                                        {sortEducation.map(edu => 
                                            <li className='about__experience_list-item' key={edu.id} >
                                                <b>{edu.institute}</b><br/>
                                                {edu.date}<br/>
                                                {edu.description}<br/>
                                                {admin.isAuth && 
                                                    <div>
                                                        <i className="bi bi-pencil-fill list-item__icon" onClick={(e) => onEdit(edu, e)}></i>
                                                        <i className="bi bi-trash3-fill list-item__icon" onClick={(e) => onRemove(edu, e)}></i>
                                                    </div>
                                                }
                                            </li>
                                        )}
                                    </ul>
                                </Tab>
                                <Tab eventKey="exp" title="Опыт">
                                    <ul className='about__experience_list'>
                                        {sortExperience.map(exp => 
                                            <li className='about__experience_list-item' key={exp.id} >
                                                <b>{exp.institute}</b><br/>
                                                {exp.date}<br/>
                                                {exp.description}<br/>
                                                {admin.isAuth && 
                                                    <div>
                                                        <i className="bi bi-pencil-fill list-item__icon" onClick={(e) => onEdit(exp, e)}></i>
                                                        <i className="bi bi-trash3-fill list-item__icon" onClick={(e) => onRemove(exp, e)}></i>
                                                    </div>
                                                }
                                            </li>
                                        )}
                                    </ul>
                                </Tab>
                            </Tabs>
                        }
                    </div>
                </div>
                <ModalEditExp show={visible} onHide={() => setVisible(false)} item={item} exp={exp} />
            </Container>
        </div>
    );
};

export default AboutPage;